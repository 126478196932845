input.default,
textarea.default {
  padding: 0.45em 0;
  font-size: var(--arc-font-size-14);
  font-family: var(--arc-font-family-body);

  border-radius: var(--arc-border-radius-lg);
  border: 1px solid var(--arc-color-border-stark);
  background: var(--arc-color-mono-white);
}

textarea.default {
  padding: 0.4em;
  border-radius: var(--radius-element);
  border: var(--arc-border-width-standard) solid var(--arc-color-border-stark);

  transition-property: border;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

textarea.default:hover {
  border-color: var(--arc-color-border-full);
}

textarea.default:focus {
  outline: 0;
  border-color: var(--arc-color-border-focus);
}

label.textareaLabel {
  color: rgba(var(--color-gray-aa-secondary), 1);
  transform-origin: left;
  transform: scale(0.8);
  font-weight: 600;
  letter-spacing: 0.03em;
}

// ---- float labels ---- //
.inputFloatLabel {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1em;
}
// ---- float labels ---- //

.textAreaLabel {
  color: var(--arc-color-text-primary);
  cursor: pointer;
}

.textAreaLabel textarea:focus + label {
  color: var(--arc-color-text-primary);
  line-height: 1.25rem;
  cursor: default;
  letter-spacing: 0.03em;
}

.iconButton {
  margin: 0.5em 0;

  .filePickerInput {
    visibility: hidden;
  }
}

button.iconButton {
  border: 0;
  background-color: transparent;
}

.validationMessage {
  color: rgba(var(--color-red-300), 1);
  margin-top: 0.25em;
  transition: opacity 0.3s ease-in-out;
}

.validationMessage.hidden {
  opacity: 0;
}
